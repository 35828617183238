<template>
    <div>
        <div
            v-if="!loading"
        >
            <b-sidebar
                id="integrationSlider"
                :title="integration.charAt(0).toUpperCase() + integration.slice(1) + ' integration'"
                right
                shadow
                width="600px"
                :body-class="[isDark ? 'night-mode' : 'light-mode']"
                :header-class="[isDark ? 'night-mode' : 'light-mode']"
            >
                <template #footer>
                    <div
                        :style="isDark ? 'background: #283046 !important;' : 'color: #4b4b4b !important; background: #f8f8f8 !important;'"
                        class="d-flex text-light align-items-center px-1 py-1"
                    >
                        <strong
                            class="mr-auto"
                        >
                            {{ loading ? 'Loading' : saving ? 'Saving' : 'Save' }} integration
                        </strong>
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            size="sm"
                            :disabled="saving || loading"
                            @click="saveIntegration(integrations)"
                        >
                            <b-spinner
                                v-if="saving || loading"
                                small
                                type="grow"
                            />
                            Submit
                        </b-button>
                    </div>
                </template>
                <div
                    v-if="integration === 'schenker'"
                    class="m-1"
                >
                    <div
                        v-if="!loading"
                    >
                        <b-card
                            title="Configuration"
                            border-variant="secondary"
                            header-border-variant="secondary"
                        >
                            <b-card-sub-title>
                                Connect your DB Schenker integration to Fulfillin
                                <span
                                    class="small"
                                >
                                    (<a
                                        href="https://apps.linnworks.net/Authorization/Authorize/0a959981-0111-4de9-a734-a8c53593d184"
                                        target="_blank"
                                    >
                                        authorize application
                                    </a>)
                                </span>
                            </b-card-sub-title>
                            <b-input-group
                                prepend="Fulfillin API key"
                            >
                                <b-form-input
                                    v-model="integrations.settings.api_key"
                                    placeholder="Input your Fulfillin API key from Linnworks"
                                />
                                <b-input-group-append>
                                    <b-button
                                        id="linnAPIExplain_2"
                                        size="sm"
                                        text="Button"
                                        :variant="integrations.is_enabled === true ? 'success' : 'danger'"
                                        @click="integrations.is_enabled = !integrations.is_enabled"
                                    >
                                        {{ integrations.is_enabled === true ? 'Enabled' : 'Disabled' }}
                                    </b-button>
                                    <b-tooltip
                                        target="linnAPIExplain_2"
                                    >
                                        The Fulfillin API key that is required to connect your integrations, which can
                                        be found in integration settings on Linnworks.
                                    </b-tooltip>
                                </b-input-group-append>
                            </b-input-group>
                            <b-overlay
                                v-if="schenkerInteg.integrations.length > 0"
                                :show="reloadPaymentPlan"
                                rounded="sm"
                                spinner-variant="primary"
                                :variant="isDark ? 'dark' : 'light'"
                            >
                                <b-card
                                    no-body
                                    class="border-dark mt-1"
                                >
                                    <b-card-header
                                        class="d-flex justify-content-between align-items-center pt-75 pb-25"
                                    >
                                        <h5 class="mb-0">
                                            Current plan limits
                                        </h5>
                                        <div>
                                            <b-badge variant="light-primary">
                                                {{ schenkerInteg.paymentPlan.plan_name }}
                                            </b-badge>
                                            <feather-icon
                                                class="ml-1 cursor-pointer"
                                                icon="RotateCwIcon"
                                                @click="refreshLinnworksPaymentPlanForSchenker"
                                            />
                                        </div>
                                        <small
                                            class="text-muted w-100"
                                        >
                                            Next payment date: {{
                                                new Date(schenkerInteg.paymentPlanData.ends_on).toLocaleDateString(undefined, {
                                                    weekday: 'long',
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric'
                                                })
                                            }}
                                        </small>
                                    </b-card-header>
                                    <b-card-body>
                                        <ul class="list-unstyled my-1">
                                            <li>
                                                <span class="align-middle"><b>Unlimited</b> integrations in total</span>
                                            </li>
                                            <li class="my-25">
                                                <span class="align-middle">
                                                    <b>{{ schenkerInteg.paymentPlanData.data.max_shipments }}</b> shipments per month
                                                </span>
                                            </li>
                                            <li>
                                                <span class="align-middle">
                                                    <b>{{ schenkerInteg.paymentPlanData.data.max_quotes }}</b> quotes per month
                                                </span>
                                            </li>
                                        </ul>
                                        <b-button
                                            v-b-tooltip.hover.top="'Currently this feature is only supported in Linnworks!'"
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            variant="primary"
                                            block
                                        >
                                            Upgrade Plan
                                        </b-button>
                                    </b-card-body>
                                </b-card>
                            </b-overlay>
                        </b-card>
                        <b-card
                            title="Integration's data"
                            sub-title="Your integration's data and settings (can only be changed in Linnworks)"
                            border-variant="secondary"
                            header-border-variant="secondary"
                        >
                            <div
                                v-if="schenkerInteg.integrations.length <= 0"
                            >
                                <b-alert
                                    variant="info"
                                    show
                                >
                                    <h4
                                        class="alert-heading"
                                    >
                                        Whoops!
                                    </h4>
                                    <div
                                        class="alert-body"
                                    >
                                        <span>Sorry, but you do not have DBSchenker integration installed in Linnworks or your API key is incorrect!</span>
                                    </div>
                                </b-alert>
                            </div>
                            <div
                                v-else
                            >
                                <b-alert
                                    variant="success"
                                    show
                                >
                                    <h4
                                        class="alert-heading"
                                    >
                                        Success!
                                    </h4>
                                    <div
                                        class="alert-body"
                                    >
                                        <span>Great! Your integration is connected to Fulfillin and working flawlessly!</span>
                                    </div>
                                </b-alert>
                            </div>
                        </b-card>
                    </div>
                </div>
                <div
                    v-if="integration === 'mirakl'"
                    class="m-1"
                >
                    <div
                        v-if="!loading"
                    >
                        <b-card
                            title="Configuration"
                            border-variant="secondary"
                            header-border-variant="secondary"
                        >
                            <b-card-sub-title>
                                Connect your Mirakl integration to Fulfillin
                                <span
                                    class="small"
                                >
                                    (<a
                                        href="https://apps.linnworks.net/Authorization/Authorize/480af5a8-2655-4343-9e10-a5d6f931b2cc"
                                        target="_blank"
                                    >
                                        authorize application
                                    </a>)
                                </span>
                            </b-card-sub-title>
                            <b-input-group
                                prepend="Fulfillin API key"
                            >
                                <b-form-input
                                    v-model="integrations.settings.api_key"
                                    placeholder="Input your Fulfillin API key from Linnworks"
                                />
                                <b-input-group-append>
                                    <b-button
                                        id="linnAPIExplain"
                                        size="sm"
                                        text="Button"
                                        :variant="integrations.is_enabled === true ? 'success' : 'danger'"
                                        @click="integrations.is_enabled = !integrations.is_enabled"
                                    >
                                        {{ integrations.is_enabled === true ? 'Enabled' : 'Disabled' }}
                                    </b-button>
                                    <b-tooltip
                                        target="linnAPIExplain"
                                    >
                                        The Fulfillin API key that is required to connect your integrations, which can
                                        be found in integration settings on Linnworks.
                                    </b-tooltip>
                                </b-input-group-append>
                            </b-input-group>
                            <b-overlay
                                v-if="miraklInteg.integrations.length > 0"
                                :show="reloadPaymentPlan"
                                rounded="sm"
                                spinner-variant="primary"
                                :variant="isDark ? 'dark' : 'light'"
                            >
                                <b-card
                                    no-body
                                    class="border-dark mt-1"
                                >
                                    <b-card-header
                                        class="d-flex justify-content-between align-items-center pt-75 pb-25"
                                    >
                                        <h5 class="mb-0">
                                            Current plan limits
                                        </h5>
                                        <div>
                                            <b-badge variant="light-primary">
                                                {{ miraklInteg.paymentPlan.plan_name }}
                                            </b-badge>
                                            <feather-icon
                                                class="ml-1 cursor-pointer"
                                                icon="RotateCwIcon"
                                                @click="refreshLinnworksPaymentPlanForMirakl"
                                            />
                                        </div>
                                        <small
                                            class="text-muted w-100"
                                        >
                                            Next payment date: {{
                                                new Date(miraklInteg.paymentPlanData.ends_on).toLocaleDateString(undefined, {
                                                    weekday: 'long',
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric'
                                                })
                                            }}
                                        </small>
                                    </b-card-header>
                                    <b-card-body>
                                        <ul class="list-unstyled my-1">
                                            <li>
                                                <span class="align-middle">
                                                    <b>{{ miraklInteg.paymentPlanData.data.max_integrations }}</b> integration in total
                                                </span>
                                            </li>
                                            <li class="my-25">
                                                <span class="align-middle">
                                                    <b>{{ miraklInteg.paymentPlanData.data.max_orders }}</b> orders per month
                                                </span>
                                            </li>
                                            <li class="my-25">
                                                <span class="align-middle">
                                                    <b>{{ miraklInteg.paymentPlanData.data.max_listings }}</b> listings per integration
                                                </span>
                                            </li>
                                            <li>
                                                <span class="align-middle">
                                                    <b>{{ miraklInteg.paymentPlanData.data.max_sales }}</b> EUR sales per month
                                                </span>
                                            </li>
                                        </ul>
                                        <b-button
                                            v-b-tooltip.hover.top="'Currently this feature is only supported in Linnworks!' +
                                                '(Linnworks>Application Store>My Applications and select Change plan or Subscribe)'"
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            variant="primary"
                                            block
                                        >
                                            Upgrade Plan
                                        </b-button>
                                    </b-card-body>
                                </b-card>
                            </b-overlay>
                        </b-card>
                        <b-card
                            title="Integration's data"
                            sub-title="Your integration's data and settings (can only be changed in Linnworks)"
                            border-variant="secondary"
                            header-border-variant="secondary"
                        >
                            <div
                                v-if="miraklInteg.integrations.length <= 0"
                            >
                                <b-alert
                                    variant="info"
                                    show
                                >
                                    <h4
                                        class="alert-heading"
                                    >
                                        Whoops!
                                    </h4>
                                    <div
                                        class="alert-body"
                                    >
                                        <span>Sorry, but you do not have any Mirakl marketplaces installed in Linnworks or your API key is incorrect!</span>
                                    </div>
                                </b-alert>
                            </div>
                            <div
                                v-for="(miraklIntegration, index) in miraklInteg.integrations"
                            >
                                <b-card
                                    :key="index"
                                    :title="miraklIntegration.account_name"
                                    :sub-title="'Integrated on ' + new Date(miraklIntegration.created_at).toLocaleString()"
                                    border-variant="secondary"
                                    header-border-variant="secondary"
                                >
                                    <b-container
                                        fluid
                                    >
                                        <b-row
                                            align-v="center"
                                            class="mb-2"
                                        >
                                            <b-col
                                                class="text-center"
                                            >
                                                API URL:
                                            </b-col>
                                            <b-col
                                                cols="9"
                                            >
                                                <b-form-input
                                                    :value="miraklIntegration.api_url_from_list === '-' ? miraklIntegration.api_url : miraklIntegration.api_url_from_list"
                                                    readonly
                                                />
                                            </b-col>
                                        </b-row>
                                        <b-row
                                            align-v="center"
                                            class="mb-2"
                                        >
                                            <b-col
                                                class="text-center"
                                            >
                                                API Token:
                                            </b-col>
                                            <b-col
                                                cols="9"
                                            >
                                                <b-input-group>
                                                    <b-form-input
                                                        :value="miraklIntegration.api_token"
                                                        :type="passwordFieldType"
                                                        readonly
                                                    />
                                                    <b-input-group-append is-text>
                                                        <feather-icon
                                                            :icon="IsAPITokenShown"
                                                            class="cursor-pointer"
                                                            @click="ShowAPIToken"
                                                        />
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </b-col>
                                        </b-row>
                                        <b-row
                                            align-v="center"
                                            class="mb-2"
                                        >
                                            <b-col
                                                class="text-center"
                                            >
                                                Integration active:
                                            </b-col>
                                            <b-col
                                                cols="9"
                                            >
                                                <b-form-checkbox
                                                    :checked="miraklIntegration.is_config_active"
                                                    disabled
                                                />
                                            </b-col>
                                        </b-row>
                                        <b-row
                                            align-v="center"
                                            class="mb-2"
                                        >
                                            <b-col
                                                class="text-center"
                                            >
                                                Auto accept orders:
                                            </b-col>
                                            <b-col
                                                cols="9"
                                            >
                                                <b-form-checkbox
                                                    :checked="(miraklIntegration.auto_accept === 'True')"
                                                    disabled
                                                />
                                            </b-col>
                                        </b-row>
                                        <b-row
                                            align-v="center"
                                        >
                                            <b-col
                                                class="text-center"
                                            >
                                                Additional shipping data:
                                            </b-col>
                                            <b-col
                                                cols="9"
                                            >
                                                <b-form-checkbox
                                                    :checked="(miraklIntegration.shipping_to_add_3 === 'True')"
                                                    disabled
                                                />
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                </b-card>
                            </div>
                        </b-card>
                    </div>
                </div>
                <div
                    v-if="integration === 'linnworks'"
                    class="m-1"
                >
                    <div
                        v-if="!loading"
                    >
                        <b-card
                            title="API config"
                            sub-title="Connection to Linnworks API"
                            border-variant="secondary"
                            header-border-variant="secondary"
                        >
                            <b-input-group
                                prepend="API Token"
                            >
                                <b-form-input
                                    v-model="integrations.settings.token"
                                    placeholder="Input your API Token"
                                />
                                <b-input-group-append>
                                    <b-button
                                        size="sm"
                                        text="Button"
                                        :variant="integrations.is_enabled === true ? 'success' : 'danger'"
                                        @click="integrations.is_enabled = !integrations.is_enabled"
                                    >
                                        {{ integrations.is_enabled === true ? 'Enabled' : 'Disabled' }}
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-card>
                        <b-card
                            title="General settings"
                            sub-title="Linnworks and Fulfillin configuration"
                            border-variant="secondary"
                            header-border-variant="secondary"
                        >
                            <b-container>
                                <b-row align-v="center" class="mb-2">
                                    <b-col class="text-center">
                                        <span>Sync type</span>
                                    </b-col>
                                    <b-col cols="8">
                                        <v-select
                                            id="s-order-type"
                                            v-model="settings.linnworksSyncType"
                                            label="text"
                                            :options="[{ text: 'Simple', value: Linnworks.TYPE_SIMPLE }, { text: 'Full order', value: Linnworks.TYPE_FULL_ORDER }]"
                                            :reduce="text => text.value"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row align-v="center" class="mb-2">
                                    <b-col class="text-center">
                                        <span>Default stock location</span>
                                    </b-col>
                                    <b-col cols="8">
                                        <v-select
                                            id="s-stock-loc"
                                            v-model="settings.linnworksLocationId"
                                            label="text"
                                            :options="stockLocations"
                                            :reduce="text => text.value"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row align-v="center" class="mb-2">
                                    <b-col class="text-center">
                                        <span>Default printer</span>
                                    </b-col>
                                    <b-col cols="8">
                                        <v-select
                                            id="s-printer"
                                            v-model="settings.linnworksShippingMethodId"
                                            label="text"
                                            :searchable="searchable"
                                            :options="printers"
                                            :reduce="text => text.value"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row align-v="center" class="mb-2">
                                    <b-col class="text-center">
                                        <span>Default shipping method</span>
                                    </b-col>
                                    <b-col cols="8">
                                        <v-select
                                            id="s-ship-method"
                                            v-model="settings.linnworksPrinterName"
                                            label="text"
                                            :searchable="searchable"
                                            :options="shippingMethods"
                                            :reduce="text => text.value"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row align-v="center" class="mb-2">
                                    <b-col class="text-center">
                                        <span>Default source</span>
                                    </b-col>
                                    <b-col cols="8">
                                        <b-form-input v-model="settings.linnworksSource" placeholder="Default source" />
                                    </b-col>
                                </b-row>
                                <b-row align-v="center" class="mb-2">
                                    <b-col class="text-center">
                                        <span>Default subsource</span>
                                    </b-col>
                                    <b-col cols="8">
                                        <b-form-input
                                            v-model="settings.linnworksSubsource"
                                            placeholder="Default subsource"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row align-v="center" class="mb-2">
                                    <b-col class="text-center">
                                        <span>Default shipping phone</span>
                                    </b-col>
                                    <b-col cols="8">
                                        <b-form-input
                                            v-model="settings.linnworksDefaultShippingPhone"
                                            placeholder="Default shipping phone"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row align-v="center" class="mb-2">
                                    <b-col class="text-center">
                                        <span>Default product group name</span>
                                    </b-col>
                                    <b-col cols="8">
                                        <b-form-input
                                            v-model="settings.defaultProductGroupName"
                                            placeholder="Default product group name"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row align-v="center" class="mb-2">
                                    <b-col class="text-center">
                                        <span>Default product channel SKU</span>
                                    </b-col>
                                    <b-col cols="8">
                                        <b-form-input
                                            v-model="settings.defaultProductChannelSku"
                                            placeholder="Default product channel SKU"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row align-v="center" class="mb-2">
                                    <b-col class="text-center">
                                        <span>Default product group price per item</span>
                                    </b-col>
                                    <b-col cols="8">
                                        <b-form-input
                                            v-model="settings.defaultProductGroupPricePerItem"
                                            placeholder="Default product group price per item"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row align-v="center" class="mb-2">
                                    <b-col class="text-center">
                                        <span>Apply country prefix</span>
                                    </b-col>
                                    <b-col cols="8">
                                        <b-form-checkbox
                                            id="checkbox-1"
                                            v-model="settings.applyCountryPrefix"
                                            name="checkbox-1"
                                        >
                                            <span style="font-size: 10px;">If this option is checked, it will add country prefix to order and stock removal info.</span>
                                        </b-form-checkbox>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </b-card>
                        <b-card
                            title="Inventory data settings"
                            sub-title="Linnworks + Fulfillin inventory data settings"
                            border-variant="secondary"
                            header-border-variant="secondary"
                        >
                            <template v-for="(field, index) of fields">
                                <div :key="index">
                                    <b-row v-if="shouldShowSectionTitle(field.mainType)">
                                        <b-col>
                                            <h4><u>{{ fetchSectionTitle(field.mainType) }}</u></h4>
                                        </b-col>
                                    </b-row>
                                    <b-card border-variant="secondary"
                                            header-border-variant="secondary"
                                    >
                                        <b-card-title>
                                            {{ field.label }}
                                            <b-badge
                                                pill
                                                variant="light-success"
                                                class="text-center badge-glow"
                                                style="font-size: 10px;"
                                            >
                                                {{ fetchSectionTitle(field.mainType) }}
                                            </b-badge>
                                        </b-card-title>
                                        <b-row align-v="center" class="mb-2">
                                            <b-col class="text-center">
                                                Sync frequency:
                                            </b-col>
                                            <b-col cols="8">
                                                <v-select
                                                    id="s-sync-freq"
                                                    v-model="field.syncFrequency"
                                                    label="text"
                                                    :options="[{ text: 'Daily', value: Linnworks.SYNC_FREQUENCY_DAILY },
                                                               { text: 'Weekly', value: Linnworks.SYNC_FREQUENCY_WEEKLY },
                                                               { text: 'Once', value: Linnworks.SYNC_FREQUENCY_ONCE }]"
                                                    :reduce="text => text.value"
                                                />
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col class="text-center">
                                                Is enabled:
                                            </b-col>
                                            <b-col cols="8">
                                                <b-form-checkbox v-model="field.isEnabled"
                                                                 variant="primary"
                                                                 :disabled="field.isAlwaysEnabled"
                                                                 @change="handleFieldChange(field)"
                                                >
                                                    <div v-if="field.isAlwaysEnabled" class="font-small-1">
                                                        This field is in default preset and can't be disabled
                                                    </div>
                                                </b-form-checkbox>
                                            </b-col>
                                        </b-row>
                                    </b-card>
                                </div>
                            </template>
                        </b-card>
                    </div>
                    <div class="d-flex justify-content-center mb-3">
                        <b-spinner v-if="loading" variant="primary" class="text-center" />
                    </div>
                </div>
            </b-sidebar>
        </div>
    </div>
</template>

<script>
import {
    BSpinner,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BContainer,
    BRow,
    BCol,
    BFormCheckbox,
    BBadge,
    BCardTitle,
    BTooltip,
    BSidebar,
    BAlert,
    BCardBody,
    BCardHeader,
    BCardSubTitle,
    VBTooltip,
    BOverlay,
} from 'bootstrap-vue'
import Axios from 'axios'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mixin as clickaway } from 'vue-clickaway'
import { Linnworks, LinnworksInventoryFieldTypes } from '@/constants'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
    components: {
        BSpinner,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCard,
        BContainer,
        BRow,
        BCol,
        BFormCheckbox,
        vSelect,
        BBadge,
        BCardTitle,
        BSidebar,
        BTooltip,
        BAlert,
        BCardBody,
        BCardHeader,
        BCardSubTitle,
        BOverlay,
        // eslint-disable-next-line vue/no-unused-components
        LinnworksInventoryFieldTypes,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    mixins: [clickaway],
    props: {
        integration: {
            type: String,
            default: 'Linnworks',
        },
        integrations: {
            type: Object,
            default: function () { },
        },
        integrationData: {
            type: Array,
            default: function () { },
        },
    },
    data() {
        return {
            loading: true,
            saving: false,
            settings: [],
            stockLocations: [],
            printers: [],
            shippingMethods: [],
            fields: [],
            fieldsSaving: [],
            data: {},
            sectionTitles: {},
            searchable: true,
            passwordFieldType: 'password',
            miraklInteg: {},
            schenkerInteg: {},
            reloadPaymentPlan: false,
            Linnworks,
        }
    },
    computed: {
        IsAPITokenShown() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },
    created() {
        window.addEventListener('keyup', this.handleKeyEvent)
        this.loadProjectSettings()
        this.sectionTitles[LinnworksInventoryFieldTypes.KEY_PRIMARY_FIELD] = { label: 'Primary fields', isShown: false }
        this.sectionTitles[LinnworksInventoryFieldTypes.KEY_DEFAULT_IMAGE] = { label: 'Image', isShown: true }
        this.sectionTitles[LinnworksInventoryFieldTypes.KEY_DEFAULT_SUPPLIER] = {
            label: 'Default supplier information',
            isShown: false
        }
        this.sectionTitles[LinnworksInventoryFieldTypes.KEY_DEFAULT_STOCK_LOCATION] = {
            label: 'Default stock location information',
            isShown: false
        }
        this.sectionTitles[LinnworksInventoryFieldTypes.KEY_EXTENDED_PROPERTIES] = {
            label: 'Extended properties',
            isShown: false
        }
        this.sectionTitles[LinnworksInventoryFieldTypes.KEY_STOCK] = { label: 'Stock information', isShown: false }
        this.loading = true
        setTimeout(() => {
            this.miraklInteg = this.integrationData.find(element => element.name === 'Mirakl')
            this.schenkerInteg = this.integrationData.find(element => element.name === 'DBSchenker')

            this.loading = false
        }, 2000)
    },
    setup() {
        const { skin } = useAppConfig()
        const isDark = computed(() => skin.value === 'dark')

        return {
            isDark,
        }
    },
    methods: {
        ShowAPIToken() {
            return this.passwordFieldType === 'password' ? this.passwordFieldType = 'text' : this.passwordFieldType = 'password'
        },
        handleFieldChange(fieldToChange) {
            this.fieldsSaving.push(fieldToChange)
            clearTimeout(this.shouldSaveTimeout)
            this.shouldSaveTimeout = setTimeout(() => {
                this.save()
            }, 1000)
        },
        loadInventoryItemConfig() {
            this.loading = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}linnworks/get-inventory-item-config`,
                data: {},
                method: 'POST',
            })
                .then(resp => {
                    this.loading = false
                    this.fields = resp.data
                })
                .catch(() => {
                    this.loading = false
                })
        },
        save() {
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}linnworks/set-inventory-item-config`,
                data: {inventoryItemFields: this.fields},
                method: 'POST',
            })
                .finally(() => {
                    this.fieldsSaving = []
                })
        },
        loadProjectSettings() {
            this.loading = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}linnworks/get-project-settings`,
                data: {},
                method: 'POST',
            })
                .then(resp => {
                    this.data = resp.data
                    this.settings = this.data.settings
                    this.stockLocations = []
                    this.printers = []
                    this.shippingMethods = []
                    this.data.stockLocations.forEach(stockLocation => this.stockLocations.push({
                        text: stockLocation.LocationName,
                        value: stockLocation.StockLocationId
                    }))
                    this.data.printers.forEach(printer => this.printers.push({
                        text: `${printer.PrinterLocationName}\\${printer.PrinterName} (${printer.Status})`,
                        value: printer.PrinterName,
                    }))
                    this.data.shippingMethods.forEach(shippingMethod => shippingMethod.postalServices.forEach(vendor => this.shippingMethods.push({
                        text: vendor.postalServiceName,
                        value: vendor.pkPostalServiceId,
                    })))
                    this.loadInventoryItemConfig()
                })
                .catch(() => {
                    this.loading = false
                })
        },
        loadIntegrations() {
            this.loading = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}project/get-integrations`,
                data: {},
                method: 'POST',
            })
                .then(resp => {
                    this.loadProjectSettings()
                    this.integrations = resp.data.data
                })
                .catch(() => {
                    this.loading = false
                    this.integrations = []
                })
        },
        saveIntegration(integration) {
            this.saving = true
            this.errors = []
            if (!integration.settings.api_key) {
                integration.settings.api_key = '-'
            }

            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}project/save-integrations`,
                data: integration,
                method: 'POST',
            })
                .then(() => {
                    if (this.integration === 'mirakl' || this.integration === 'schenker') {
                        this.saving = false
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Success!',
                                icon: 'CheckIcon',
                                text: 'The integration was saved successfully.',
                                variant: 'primary',
                            },
                        })
                    } else {
                        this.saveProjectSettings()
                    }

                    this.$emit('loadIntegrations')
                })
                .catch(err => {
                    this.loading = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error!',
                            icon: 'XIcon',
                            text: '' + err,
                            variant: 'danger',
                        },
                    })
                })
        },
        saveProjectSettings() {
            this.saving = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}linnworks/set-project-settings`,
                data: { settings: this.settings },
                method: 'POST',
            })
                .then(() => {
                    this.saveInventoryItemSettings()
                })
                .catch(() => {
                    this.loading = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error!',
                            icon: 'XIcon',
                            text: 'Saving integration failed. Please check if credentials are correct and their site is up and running.',
                            variant: 'danger',
                        },
                    })
                })
        },
        saveInventoryItemSettings() {
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}linnworks/set-inventory-item-config`,
                data: { inventoryItemFields: this.fields },
                method: 'POST',
            })
                .then(() => {
                    this.saving = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Success!',
                            icon: 'CheckIcon',
                            text: 'The integration was saved successfully.',
                            variant: 'primary',
                        },
                    })
                })
                .catch(() => {
                    this.loading = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error!',
                            icon: 'XIcon',
                            text: 'Saving integration failed. Please check if credentials are correct and their site is up and running. (saveInventoryItemSettings)',
                            variant: 'danger',
                        },
                    })
                })
        },
        pullLinnworksInventory() {
            this.linnworksInventorySyncLoading = true
            this.linnworksInventorySyncDone = false
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}linnworks/refresh-inventory`,
                data: {},
                method: 'POST',
            })
                .then(() => {
                    this.linnworksInventorySyncLoading = false
                    this.linnworksInventorySyncDone = true
                    setTimeout(() => {
                        this.linnworksInventorySyncDone = false
                    }, 5000)
                })
        },
        refreshLinnworksPaymentPlanForMirakl() {
            this.reloadPaymentPlan = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}mirakl/update-payment-plan`,
                data: {},
                method: 'POST',
            })
                .then(resp => {
                    this.reloadPaymentPlan = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Success!',
                            icon: 'CheckIcon',
                            text: '' + resp.data.Message,
                            variant: 'primary',
                        },
                    })
                })
        },
        refreshLinnworksPaymentPlanForSchenker() {
            this.reloadPaymentPlan = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}schenker/update-payment-plan`,
                data: {},
                method: 'POST',
            })
                .then(resp => {
                    this.reloadPaymentPlan = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Success!',
                            icon: 'CheckIcon',
                            text: '' + resp.data.Message,
                            variant: 'primary',
                        },
                    })
                })
        },
        shouldShowSectionTitle(sectionKey) {
            return !!(this.sectionTitles[sectionKey] && this.sectionTitles[sectionKey].isShown === false)
        },
        fetchSectionTitle(sectionKey) {
            if (!this.sectionTitles[sectionKey]) {
                return ''
            }

            this.sectionTitles[sectionKey].isShown = true
            return this.sectionTitles[sectionKey].label
        },

    },
}
</script>

<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.night-mode {
    background: #283046 !important;
}

.light-mode {
    background: #f7f7f7 !important;
}
</style>