<template>
    <div>
        <div
            v-if="loading"
            class="row mx-auto justify-content-center align-items-center flex-column"
        >
            <div
                class="p-2"
            >
                <b-spinner
                    variant="primary"
                />
            </div>
        </div>
        <div
            v-if="noIntegrationsFound"
        >
            <b-alert
                variant="danger"
                class="m-4"
                show
            >
                <h4
                    class="alert-heading"
                >
                    Whoops!
                </h4>
                <div
                    class="alert-body"
                >
                    Your project has no allowed integrations!
                    If you think this might be a mistake, please contact us through support ticket.
                </div>
            </b-alert>
        </div>
        <div
            v-if="!loading && !noIntegrationsFound"
        >
            <b-card
                v-if="!isUsingMobile"
                no-body
            >
                <template #header>
                    <div
                        style="float: left;"
                    >
                        <b-card-title>
                            Fulfillin integrations
                        </b-card-title>
                    </div>
                    <div
                        style="float: right;"
                    >
                        <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            disabled
                        >
                            Add general integration
                        </b-button>
                    </div>
                </template>
                <b-card-body>
                    <b-card-sub-title>
                        Accessible Fulfillin external shipping & marketplace platform integrations
                    </b-card-sub-title>
                    <b-container>
                        <b-row
                            align-v="center"
                        >
                            <b-col
                                class="text-center"
                            >
                                <span>Most popular integrations</span>
                                <feather-icon
                                    icon="ArrowRightIcon"
                                    size="20"
                                    class="mr-50"
                                />
                            </b-col>
                            <b-col
                                cols="8"
                            >
                                <swiper
                                    class="swiper-centered-slides-2 p-1"
                                    :options="swiperOptions"
                                    @clickSlide="handleSwiperSlideClick"
                                >
                                    <swiper-slide
                                        v-for="(data,index) in swiperData"
                                        :key="index"
                                        v-b-toggle.integrationSlider
                                        :disabled="!data.integrated"
                                        class="rounded swiper-shadow py-1 px-3 d-flex"
                                    >
                                        <b-img
                                            :src="require(`../../assets/images/logo/integrations/${data.integration}.png`)"
                                            fluid
                                        />
                                    </swiper-slide>
                                    <div
                                        slot="button-next"
                                        class="swiper-button-next"
                                    />
                                    <div
                                        slot="button-prev"
                                        class="swiper-button-prev"
                                    />
                                </swiper>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-card-body>
            </b-card>
            <b-card
                no-body
            >
                <b-card-body>
                    <b-card-title>Integrations</b-card-title>
                    <b-card-sub-title>A list of integrations that are added to your Fulfillin account</b-card-sub-title>
                    <b-container style="margin-top: 20px;">
                        <b-row align-v="center">
                            <b-col
                                v-for="(data, idx) in swiperData"
                                :key="idx"
                                cols="6"
                                md="auto"
                            >
                                <b-button
                                    v-b-toggle.integrationSlider
                                    variant="flat-primary"
                                    :disabled="!data.integrated || (data.integration === 'mirakl' && miraklLoading) || (data.integration === 'schenker' && schenkerLoading)"
                                    @click="setIntegrationSlider(data.integration)"
                                >
                                    <div
                                        v-if="data.integration === 'mirakl' && data.integrated"
                                    >
                                        {{ miraklInfo.totalIntegrations }}
                                        {{ miraklInfo.totalIntegrations === 1 ? 'marketplace' : 'marketplaces' }}
                                    </div>
                                    <b-img
                                        v-if="!isDark"
                                        :src="require(`../../assets/images/logo/integrations/${data.integration_dark}.png`)"
                                    />
                                    <b-img
                                        v-if="isDark"
                                        :src="require(`../../assets/images/logo/integrations/${data.integration_light}.png`)"
                                    />
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-card-body>
            </b-card>
            <SidesSlider
                :integration="sliderIntegration"
                :integration-data="integrationsInfo"
                :integrations="selectedIntegration"
                @loadIntegrations="loadIntegrations()"
            />
        </div>
    </div>
</template>

<style>
</style>
<script>

import Axios from 'axios'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import {
    BCard,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BImg,
    BContainer,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BAlert,
} from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import '../../@core/scss/base/plugins/extensions/ext-component-swiper.scss'
import Ripple from 'vue-ripple-directive'
import {computed} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import {
    VBToggle,
    VBTogglePlugin,
} from 'bootstrap-vue'
import SidesSlider from '../Misc/SidesSlider.vue'
import {isMobile} from 'mobile-device-detect'

export default {
    components: {
        Swiper,
        SwiperSlide,
        SidesSlider,
        BSpinner,
        BCardTitle,
        BCard,
        BCardBody,
        BCardSubTitle,
        BImg,
        BContainer,
        BCol,
        BRow,
        BButton,
        BAlert,
    },
    directives: {
        Ripple,
        'b-toggle': VBToggle,
        VBTogglePlugin,
    },
    data() {
        return {
            errors: [],
            integrations: [],
            loading: false,
            linnworksSettingsModalOpen: false,
            linnworksInventorySyncLoading: false,
            linnworksInventorySyncDone: false,
            sideSliderVisible: false,
            noIntegrationsFound: false,
            miraklLoading: true,
            schenkerLoading: true,
            sliderIntegration: '',
            selectedIntegration: {},
            integrationsInfo: [],
            miraklInfo: {},
            schenkerInfo: {},
            isUsingMobile: isMobile,
        }
    },
    setup() {
        const swiperOptions = {
            slidesPerView: 'auto',
            centeredSlides: true,
            spaceBetween: 30,
            slideToClickedSlide: true,
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        }

        const swiperData = [
            {
                integration: 'linnworks',
                integration_dark: 'linn_integ_dark',
                integration_light: 'linn_integ_light',
                integrated: false,
            },
            {
                integration: 'mirakl',
                integration_dark: 'mirakl_integ_dark',
                integration_light: 'mirakl_integ_light',
                integrated: false,
            },
            {
                integration: 'amazon',
                integration_dark: 'amazon_integ_dark',
                integration_light: 'amazon_integ_light',
                integrated: false,
            },
            {
                integration: 'shopify',
                integration_dark: 'shopify_integ_dark',
                integration_light: 'shopify_integ_light',
                integrated: false,
            },
            {
                integration: 'fnac',
                integration_dark: 'fnac_integ_dark',
                integration_light: 'fnac_integ_light',
                integrated: false,
            },
            {
                integration: 'cdiscount',
                integration_dark: 'cdiscount_integ_dark',
                integration_light: 'cdiscount_integ_light',
                integrated: false,
            },
            {
                integration: 'etsy',
                integration_dark: 'etsy_integ_dark',
                integration_light: 'etsy_integ_light',
                integrated: false,
            },
            {
                integration: 'realde',
                integration_dark: 'realde_integ_dark',
                integration_light: 'realde_integ_light',
                integrated: false,
            },
            {
                integration: 'schenker',
                integration_dark: 'schenker_integ_dark',
                integration_light: 'schenker_integ_light',
                integrated: false,
            },
        ]

        const {skin} = useAppConfig()
        const isDark = computed(() => skin.value === 'dark')

        return {
            swiperOptions,
            swiperData,
            isDark,
        }
    },
    created() {
        this.loadIntegrations()
    },
    methods: {
        handleSwiperSlideClick(index, reallyIndex) {
            if (reallyIndex === 0 && this.swiperData[0].integrated) {
                this.setIntegrationSlider('linnworks')
            } else if (reallyIndex === 1 && this.swiperData[1].integrated) {
                this.setIntegrationSlider('mirakl')
            } else if (reallyIndex === 8 && this.swiperData[8].integrated) {
                this.setIntegrationSlider('schenker')
            }
        },
        loadIntegrations() {
            this.loading = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}project/get-integrations`,
                data: {},
                method: 'POST',
            })
                .then(resp => {
                    this.integrations = resp.data.data

                    if (this.integrations.length <= 0) {
                        this.noIntegrationsFound = true
                        this.loading = false
                    }

                    if (this.integrations.find(integration => integration.name === 'Linnworks') != null) {
                        this.swiperData[0].integrated = true
                    }
                    if (this.integrations.find(integration => integration.name === 'Mirakl') != null) {
                        this.loadMiraklInfo()
                        this.swiperData[1].integrated = true
                    }
                    if (this.integrations.find(integration => integration.name === 'DBSchenker') != null) {
                        this.loadSchenkerInfo()
                        this.swiperData[8].integrated = true
                    }

                    setTimeout(() => {
                        this.loading = false
                    }, 1500)
                })
                .catch(() => {
                    this.loading = false
                    this.integrations = []
                })
        },
        loadSchenkerInfo() {
            const apiKey = this.integrations.find(integration => integration.name === 'DBSchenker').settings.api_key
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}schenker/get-integration-info`,
                data: {
                    api_key: apiKey
                },
                method: 'POST'
            })
                .then(resp => {
                    this.schenkerInfo = resp.data
                    this.integrationsInfo.push(this.schenkerInfo)
                    this.schenkerLoading = false
                })
        },
        loadMiraklInfo() {
            const apiKey = this.integrations.find(integration => integration.name === 'Mirakl').settings.api_key
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}mirakl/get-integration-info`,
                data: {
                    api_key: apiKey
                },
                method: 'POST'
            })
                .then(resp => {
                    this.miraklInfo = resp.data
                    this.integrationsInfo.push(this.miraklInfo)
                    this.miraklLoading = false
                })
        },
        setIntegrationSlider(integration) {
            switch (integration) {
            case 'mirakl':
                this.selectedIntegration = this.integrations.find(integration => integration.name === 'Mirakl')
                break
            case 'linnworks':
                this.selectedIntegration = this.integrations.find(integration => integration.name === 'Linnworks')
                break
            case 'schenker':
                this.selectedIntegration = this.integrations.find(integration => integration.name === 'DBSchenker')
                break
            }
            this.sliderIntegration = integration
        },
    },
}
</script>
