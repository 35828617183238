<template>
    <div>
        <div class="m-content">
            <div class="m-portlet m-portlet--full-height m-portlet--tabs  ">
                <div class="tab-content">
                    <integration-settings />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IntegrationSettings from '@/components/Project/IntegrationSettings.vue'

export default {
    name: 'settings.integrations',
    components: {
        IntegrationSettings,
    },
}
</script>
